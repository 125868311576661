import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export const CONTRACT_CONFIRMATION_DAY_START_TIME_ERROR = 'contractConfirmationDayStartTime';

export function contractConfirmationDayStartTimeValidator(contractStartTime: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const contractStartDatetime = DateTime.fromSQL(contractStartTime);
    const confirmationStartDatetime = DateTime.fromSQL(control.value);

    return confirmationStartDatetime.isValid &&
      confirmationStartDatetime >= contractStartDatetime.minus({ minutes: 30 })
      ? null
      : { [CONTRACT_CONFIRMATION_DAY_START_TIME_ERROR]: true };
  };
}
