import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ScheduleDayForm } from '@dps/shared/components';
import { MIN_CONTRACT_CONFIRMATION_DAY_DURATION } from '@dps/shared/constants';
import { calculateContractDuration } from '@dps/shared/functions';
import { ContractDayScheduleModel } from '@dps/shared/models';

export const CONTRACT_CONFIRMATION_DAY_MIN_DURATION_ERROR = 'contractConfirmationDayMinDuration';

export function contractConfirmationDayMinDurationValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dayScheduleForm = control as FormGroup<ScheduleDayForm>;
    const { fromTime, toTime } = dayScheduleForm.getRawValue();

    if (!fromTime || !toTime) return null;

    const confirmationDayDuration = calculateContractDuration(
      dayScheduleForm.getRawValue() as ContractDayScheduleModel
    );

    return confirmationDayDuration.as('hours') >= MIN_CONTRACT_CONFIRMATION_DAY_DURATION.as('hours')
      ? null
      : {
          [CONTRACT_CONFIRMATION_DAY_MIN_DURATION_ERROR]: true,
        };
  };
}
